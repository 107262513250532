import React, {useContext, useEffect} from "react"
import 'mobx-react-lite/batchingForReactDom'
import LoginLayout from "../components/layouts/loginLayout"
import PasswordInput from "../components/passwordInput";
import {Button, Form, Row, Col} from "react-bootstrap";
import {observable} from "mobx";
import Card from "react-bootstrap/Card";
import userService from "../services/user";
import UsernameInput from "../components/usernameInput";
import { Link } from "gatsby"
import alertService from "../services/alert";
import * as messages from "../components/ui_messages";
import * as alerts from "../models/alert";
import * as EmailValidator from 'email-validator';
import AppAlert from "../components/appAlert";
import { GlobalStateContext} from "../context/GlobalContextProvider";

const LoginPage = () => {
    let password = observable({val:""});
    let username = observable({val:""});

    const state = useContext(GlobalStateContext);


    console.log(state);
    async function handleSubmit(event) {
        event.preventDefault();

        var validationMessage = ValidateForm();

        if (validationMessage.toString().length > 0)
        {
            alertService.clear('login');
            alertService.push(new alerts.Alert(validationMessage, 'login', alerts.AlertType.Error))   
            return;
        }

        userService.login(username.val, password.val);
    }

    const registerButton = (state.mobile)
        ? null
        : <Col>
            <Link to="/register">Register</Link>
        </Col>;

    function ValidateForm()
    {
        if (password.val.length == 0 || username.val.length == 0)
        {
            return messages.LOGIN_EMAIL_PASSWORD_REQUIRED;
        }
                
        let email = username.val as string;

        if (!EmailValidator.validate(email))
        { 
            return messages.REGISTER_INVALID_EMAIL_FORMAT;
        }

        return "";
    }

    return (
        <LoginLayout title="Login" >
                <Card bg='light' className="p-2 mb-5  container">
                    <Card.Body className="pt-3 pb-2">
                        <AppAlert/>
                        <h1>Login</h1>
                        <Form>
                            <UsernameInput name="username" value={username} />
                            <PasswordInput name="password" value={password} />
                            <Button type={'submit'} variant="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                            
                            <Row style={{ paddingTop: 30 }}>
                                {registerButton}
                                <Col>
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            <p>Having issues? Please <a target={"_blank"} href={"mailto:info@seventeendays.org"}>email us.</a></p>
        </LoginLayout>
    )
}
export default LoginPage