import React from "react";
import {Form, InputGroup} from "react-bootstrap";
import {MdVisibility, MdVisibilityOff} from "react-icons/all";
import {observer, useLocalStore} from "mobx-react";

const UsernameInput = observer((props) => {

    function onChange(event) {
        props.value['val'] = event.target.value;
    }
    
    return <Form.Group controlId="formBasicEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" name={props.name} value={props.value.val} onChange={onChange} />
    </Form.Group>

})

export default UsernameInput