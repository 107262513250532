/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import Helmet from 'react-helmet'
import { Container } from "react-bootstrap"
import logo from "../../images/logo.png"
import {navigate} from "gatsby";

type LoginLayoutProps = {
    children: any,
    title: string
}

class LoginLayout extends React.Component<LoginLayoutProps> {

    readonly children: any;
    readonly title: string;

    constructor(props: LoginLayoutProps) {
        super(props);
        this.children = props.children;
        this.title = props.title;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.title}</title>
                    <body className="unauthenticated"/>
                </Helmet>
                <Container fluid className="px-0 main" id="intro_layout">
                    <img src={logo} alt="SeventeenDays" style={{height:'20vh', padding:"1vh", cursor:"pointer"}} onClick={()=>navigate("/")} />
                    {this.children}
                </Container>
            </>)
    }
}

export default LoginLayout